//
//
//
//
//
//
//
//
//

export default {
    name: "Loading",
    props: {
        labelText: {
            type: String,
            default: "загрузка…",
        },
        startLoading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isLoading() {
            return (
                this.$wait.is("cart.delivery") ||
                this.$wait.is("cart.payment") ||
                this.$wait.is("order.next_step") ||
                this.$wait.is("cart.save") ||
                this.$wait.is("delivery") ||
                this.startLoading
            );
        },
    },
};
