import { render, staticRenderFns } from "./pages.vue?vue&type=template&id=5617bc22&"
import script from "./pages.vue?vue&type=script&lang=js&"
export * from "./pages.vue?vue&type=script&lang=js&"
import style0 from "./pages.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LendingPage: require('/usr/src/app/components/LendingPage.vue').default,CustomPage: require('/usr/src/app/components/CustomPage.vue').default,Loading: require('/usr/src/app/components/common/Loading.vue').default,PageNotFound: require('/usr/src/app/components/PageNotFound.vue').default})
