//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ActionTree, GetterTree, MutationTree, mapGetters, mapState } from "vuex";
import cityLinksHelper from "../util/city-links-helper";
import LandingBannersPreloadMixin from "../mixins/LandingBannersPreloadMixin";
import { isPagesPage } from "../service/catalog-router/helpers";
import urlsList from "../service/static-page-handler/urlList";
import { MetaState } from "~/store_types/meta.types";
import { RootState } from "~/store_types/index.types";

export default {
    name: "Pages",
    mixins: [LandingBannersPreloadMixin],
    async fetch() {
        if (process.client) {
            location.reload();
            return;
        }

        const route = this.$router.currentRoute;
        const path = cityLinksHelper.cleanCityPath(route.path);

        await Promise.all([
            // TODO заменить вывод статичных страниц this.customPageData из СУ
            (async () => {
                try {
                    this.customLendingData = await this.$api.get("/info/landingPage", {
                        url: path,
                        city_id: this.city.id,
                    });
                } catch (error) {
                    console.warn("Ошибка запроса страницы");
                    console.error(error);
                }
            })(),
        ]);
        // вывод статичных страниц
        let filePath = null;
        let fsHelpers = null;

        if (!process.client) {
            filePath = require("path");
            fsHelpers = require("../util/fs-helpers").default;
        }

        if (!this.customLendingData && urlsList[route.path]) {
            const path = filePath.resolve("", "./service/static-page-handler/pageContent/" + urlsList[route.path]);
            this.customPageData = JSON.parse((await fsHelpers.readFile(path)).toString());
            console.log(this.customPageData);
        }
        // ======================//
        this.isLoading = false;
        if (!this.customPageData && !this.customLendingData) {
            this.$nuxt.context.res.statusCode = 404;
            this.pageNotFound = true;
            return;
        }

        await this.$store.dispatch(
            "meta/setTextPageMeta",
            this.customPageData?.title ?? this.customLendingData.title
        );
        await this.preloadBanner(this.customLendingData?.banner);
    },
    data() {
        return {
            isLoading: true,
            customPageData: null,
            customLendingData: null,
            pageNotFound: false,
        };
    },
    computed: {
        ...mapState("products", { catalog_products: "products" }),
        ...mapState("delivery", ["city"]),
    },
    watch: {
        $route(oldVal, newVal) {
            if (process.client && newVal && isPagesPage(newVal) && newVal?.path !== oldVal?.path) {
                location.reload();
            }
        },
    },
};
