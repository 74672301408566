//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";

export default {
    name: "CustomPage",
    props: ["customPageData"],
    data() {
        return {
            isEmptyLayout: false,
            isVisualEditor: false,
            openedElement: false,
        };
    },
    computed: {
        ...mapState("products", ["products"]),
        pageProducts() {
            if (!this.products) {
                return [];
            }
            return this.products.filter((prod) => {
                return this.customPageData.products.find((pId) => parseInt(pId) == prod.id);
            });
        },
        paragraphs() {
            return [...this.content.matchAll(/<.{1,5}>.*<\/.{1,5}>/g)];
        },
    },
    mounted() {
        this.toggleLabels();
        this.checkDOM();
    },
    methods: {
        toggleLabels() {
            const lnks = document.getElementsByClassName("collapseLink");
            for (let i = 0; i < lnks.length; i++) {
                lnks[i].parentElement.addEventListener("click", (e) => {
                    e.preventDefault();

                    const id = "id" in e.target.dataset ? e.target.dataset.id : e.target.children[0].dataset.id;
                    const container = document.getElementById("collapseContent_" + id);

                    if (container.classList.contains("active")) {
                        // closing
                        container.classList.remove("active");
                        container.style.setProperty("display", "none");
                        lnks[i].classList.remove("active-line");

                        this.openedElement = {
                            container: null,
                            link: null,
                        };
                    } else {
                        // opening
                        container.classList.add("active");
                        container.style.removeProperty("display");
                        lnks[i].classList.add("active-line");

                        if (this.openedElement.container && this.openedElement.link) {
                            this.openedElement.container.classList.remove("active");
                            this.openedElement.container.style.setProperty("display", "none");
                            this.openedElement.link.classList.remove("active-line");
                        }

                        this.openedElement = {
                            container,
                            link: lnks[i],
                        };
                    }
                });
            }

            const paragraphs = document.querySelectorAll(".collapseContent");
            for (let i = 0; i < paragraphs.length; i++) {
                paragraphs[i].addEventListener("click", () => {
                    if (paragraphs[i].classList.contains("active")) {
                        // closing
                        paragraphs[i].classList.remove("active");
                        paragraphs[i].style.setProperty("display", "none");
                        lnks[i].classList.remove("active-line");

                        this.openedElement = {
                            container: null,
                            link: null,
                        };
                    } else {
                        // opening
                        paragraphs[i].classList.add("active");
                        paragraphs[i].style.removeProperty("display");
                        lnks[i].classList.add("active-line");
                    }
                });
            }
        },
        matchesTo(htmlStr) {
            return htmlStr.match(/<\s?img.*\/>/) != null;
        },
        checkDOM() {
            const images = document.querySelectorAll(".page-content > p > a > img");
            console.dir(images);
            images.forEach((image) => {
                image.parentElement.parentElement.classList.add("image-inside");
            });
        },
    },
};
