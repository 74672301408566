import Vue, { ComponentOptions } from "vue";
import { BannerPickedLayers } from "../service/storages/banners-storage/types";
import { preloadBannerFirstImages } from "../service/storages/image-base64-uri-storage/utils";

const mixin: ComponentOptions<Vue> = {
    methods: {
        async preloadBanner(banner: BannerPickedLayers | undefined) {
            if (!banner || !banner.layers) {
                return;
            }
            await preloadBannerFirstImages(banner);
        },
    },
};

export default mixin;
