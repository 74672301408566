import { render, staticRenderFns } from "./PageNotFound.vue?vue&type=template&id=11f08d5e&scoped=true&"
import script from "./PageNotFound.vue?vue&type=script&lang=js&"
export * from "./PageNotFound.vue?vue&type=script&lang=js&"
import style0 from "./PageNotFound.vue?vue&type=style&index=0&id=11f08d5e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11f08d5e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AllCustomButtons: require('/usr/src/app/components/common/AllCustomButtons.vue').default,NuxtCityLink: require('/usr/src/app/components/common/NuxtCityLink.vue').default})
